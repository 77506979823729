import { useMemo, useState } from "react";
import { Typography, Table, Segmented } from "antd";

import IpLink from "components/link/Ip";
import CompanyLink from "components/link/Company";
import CountrySelector from "components/selector/CountrySelect";

import useQuery from "hooks/useQuery";
import { periodOptionsWithAll } from "lib/periodOptions";
import supabase from "lib/supabase/main";
import formatNumber from "lib/format/number";
import { loadIpBlacklist } from "api/ip";
//import ButtonSelect from "components/ButtonSelect"

const { Title } = Typography;

async function loadTopIps(options = {}) {
    const {
        data: { rows, columns },
        error,
    } = await supabase.functions.invoke("query", {
        body: {
            report: "top_ips",
            options,
            replica: true,
        },
        method: "POST",
    });

    const ips = rows.map((row) => row.ip);
    const {
        data: { rows: blacklisted },
    } = await loadIpBlacklist({ ip: ips });
    rows.forEach((row) => {
        const ip = row.ip;
        const bl = blacklisted?.find((b) => b.ip === ip);
        row.blacklisted = !!bl;
        row.blacklist_description = bl?.description;
    });

    return { data: rows, columns, error };
}

function TopVictims({
    title = "Top victims",
    showToolbar = true,
    bordered = false,
    showCompanies = true,
    showCountrySelect = true,
    ipLink = true,
    titleLevel = 4,
    params = {},
}) {
    const [period, setPeriod] = useState("last7");
    const [country, setCountry] = useState(null);

    /*const loadFn = useMemo(
        () => params.domainId ? loadTopIps : quickLoadTopIps,
        [ params.domainId ]
    );*/

    const { data, isLoading } = useQuery(
        loadTopIps,
        [period, country, ...(Object.values(params) || [])],
        {
            params: {
                limit: 10,
                country,
                ...params,
                period,
            },
        },
        Object.values(params)
    );

    const columns = useMemo(() => {
        return [
            {
                dataIndex: "ip",
                key: "ip",
                title: "IP",
                render: (ip, row) => {
                    if (ipLink === true) {
                        return (
                            <IpLink
                                ip={ip}
                                blacklisted={row.blacklisted}
                                blacklistDescription={row.blacklist_description}
                            />
                        );
                    } else if (ipLink === false) {
                        return ip;
                    } else if (typeof ipLink === "function") {
                        return ipLink(
                            ip,
                            row.blacklisted,
                            row.blacklist_description
                        );
                    }
                },
            },
            showCompanies
                ? {
                      className: "table-cell-nowrap",
                      dataIndex: "company_name",
                      key: "company_name",
                      title: "Company",
                      render: (name, row) => (
                          <CompanyLink id={row.entity_id} name={name} />
                      ),
                  }
                : null,
            {
                dataIndex: "dns_cnt",
                key: "dns_cnt",
                title: "Dns log entries",
                render: formatNumber,
            },
            {
                dataIndex: "tarpit_cnt",
                key: "tarpit_cnt",
                title: "Tarpit log entries",
                render: formatNumber,
            },
        ].filter((c) => !!c);
    }, [showCompanies, ipLink]);

    return (
        <div className="small-report report-top-victims">
            {showToolbar && (
                <div className="toolbar">
                    {title && <Title level={titleLevel}>{title}</Title>}
                    <Segmented
                        className="toolbar-right"
                        value={period}
                        onChange={setPeriod}
                        options={periodOptionsWithAll}
                    />
                    {showCountrySelect && (
                        <CountrySelector
                            size="small"
                            variant="filled"
                            allowClear
                            showSearch={true}
                            placeholder="Country"
                            value={country}
                            onChange={setCountry}
                        />
                    )}
                </div>
            )}
            <Table
                className={bordered ? null : "table-transparent-header"}
                sticky
                size="small"
                bordered={bordered}
                loading={isLoading}
                dataSource={data}
                columns={columns}
                rowKey="ip"
                pagination={false}
            />
        </div>
    );
}

export default TopVictims;
