import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import { Typography } from "antd"

const { Title } = Typography;

function DnsLog() {

    return (
        <Page className="page-dns-log">
            <DnsLogTable
                ipLink
                before={<Title level={1}>Dns log</Title>}
                toolbarPortal="header"
                toolbarSize="large"
                stateMode="both"
                initialParams={{ withThreat: true, withBlacklist: true, withGeo: true }} />
        </Page>
    )

}

export default DnsLog