import { useCallback, useMemo, useState } from "react";
import {
    Input,
    Table,
    Tooltip,
    Button,
    Select,
    Descriptions,
    Dropdown,
} from "antd";
import {
    SearchOutlined,
    DownloadOutlined,
    SyncOutlined,
    CheckOutlined,
    DownOutlined,
} from "@ant-design/icons";
import ipaddr from "ipaddr.js";
import defaultLocale from "antd/locale/en_US";

import CountrySelector from "components/selector/CountrySelect";

import domainNameRegex from "lib/domainNameRegex";
import useQuery from "hooks/useQuery";
import { loadDnsLog, columns, expandedColumns } from "api/dnsLog";
import useSwallowEventCallback from "hooks/useSwallowEventCallback";
import { downloadCsv } from "lib/csv";
import useQueryOptions from "hooks/useQueryOptions";
import useDualState from "hooks/useDualState";
import async from "lib/async";
import useUpdateEffect from "hooks/useUpdateEffect";
import { Portal } from "components/Portal";

const defaultQueryOptions = [
    { value: "ip", label: "IP" },
    { value: "companyName", label: "Company name" },
    { value: "companyDomain", label: "Company domain" },
    { value: "questionDomain", label: "Question domain" },
    { value: "threat", label: "Threat" },
];

const params = [
    {
        name: "country",
        default: null,
    },
    {
        name: "query",
        default: "",
        serialize: (v) => (v ? v.trim() : undefined),
    },
    {
        name: "queryBy",
        default: null,
    },
    {
        name: "companyId",
        default: null,
    },
    {
        name: "domainId",
        default: null,
    },
    {
        name: "blacklisted",
        default: null,
        autoApply: true,
        serialize: (v) =>
            v !== null && v !== undefined && v !== ""
                ? v.toString()
                : undefined,
        unserialize: (v) =>
            v === "true" ? true : v === "false" ? false : null,
    },
];

const locale = {
    ...defaultLocale.Table,
    emptyText: "No data in the last two days",
};

function DnsLog({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    allowDateRange = true,
    allowSearch = true,
    allowCsv = true,
    allowMore = true,
    domainLink = true,
    ipLink = false,
    excludeQueryOptions = [],
    onLoadMore = null,
    initialParams = {},
}) {
    const queryOptions = useQueryOptions(
        defaultQueryOptions,
        excludeQueryOptions
    );

    const {
        country,
        setCountry,
        query,
        setQuery,
        queryBy,
        setQueryBy,
        blacklisted,
        setBlacklisted,
        //dateRange, setDateRange,
        all,
        apply,
    } = useDualState({ params, mode: stateMode, defaults: initialParams });
    const [page, setPage] = useState(0);
    const [downloading, setDownloading] = useState(false);

    const {
        data,
        isLoading,
        reset,
        extraData = {},
    } = useQuery(
        loadDnsLog,
        [all, page, ...(Object.values(initialParams) || [])],
        {
            rowIdKey: "id",
            params: { ...initialParams, page, ...all },
            initialData: [],
            append: true,
        },
        Object.values(initialParams) || []
    );
    const { hasMore } = extraData;

    const tableColumns = useMemo(() => {
        const fetchedColumns = extraData.columns || [];
        let tableColumns = columns.filter(
            (c) => fetchedColumns.indexOf(c.dataIndex) !== -1
        );

        if (domainLink === false) {
            const inx = tableColumns.findIndex(
                (c) => c.dataIndex === "question_domain"
            );
            if (inx !== -1) {
                delete tableColumns[inx].render;
            }
        }

        if (ipLink === false) {
            const inx = tableColumns.findIndex((c) => c.dataIndex === "ip");
            if (inx !== -1) {
                delete tableColumns[inx].render;
            }
        } else if (typeof ipLink === "function") {
            const inx = tableColumns.findIndex((c) => c.dataIndex === "ip");
            if (inx !== -1) {
                tableColumns[inx].render = (ip, row) =>
                    ipLink(ip, row.blacklisted);
            }
        }

        return tableColumns;
    }, [extraData?.columns, domainLink, ipLink]);

    const onSelectOption = useCallback(
        ({ key }) => {
            const [type, value] = key.split("/");
            if (type === "blacklists") {
                setBlacklisted(
                    value === "" ? null : value === "true" ? true : false
                );
            }
        },
        [setBlacklisted]
    );

    const optionsMenu = useMemo(() => {
        const blacklistOptions = [
            {
                key: "blacklists/",
                label: "All IPs",
                icon: blacklisted === null ? <CheckOutlined /> : null,
            },
            {
                key: "blacklists/true",
                label: "Only blacklisted",
                icon: blacklisted === true ? <CheckOutlined /> : null,
            },
            {
                key: "blacklists/false",
                label: "Only not blacklisted",
                icon: blacklisted === false ? <CheckOutlined /> : null,
            },
        ];

        const options = [
            {
                value: null,
                label: "Blacklists",
                type: "group",
                children: blacklistOptions,
            },
        ];

        return {
            items: options,
            size: toolbarSize,
            multiple: true,
            onClick: onSelectOption,
            selectedKeys: [
                `blacklists/${
                    blacklisted === true
                        ? "true"
                        : blacklisted === false
                        ? "false"
                        : ""
                }`,
            ].filter(Boolean),
        };
    }, [toolbarSize, onSelectOption, blacklisted]);

    useUpdateEffect(() => reset(), [all, reset]);

    const loadNextPage = useCallback(() => {
        onLoadMore ? onLoadMore() : setPage((prev) => prev + 1);
    }, [setPage, onLoadMore]);

    const onQueryChange = useCallback(
        (e) => {
            const query = e.target.value.trim();
            if (ipaddr.isValid(query)) {
                setQueryBy("ip");
            } else if (
                query.indexOf("/") !== -1 &&
                ipaddr.isValid(query.split("/")[0])
            ) {
                setQueryBy("cidr");
            } else if (query.match(domainNameRegex)) {
                setQueryBy("companyDomain");
            } else {
                if (
                    queryBy === "companyDomain" ||
                    queryBy === "cidr" ||
                    queryBy === "ip"
                ) {
                    setQueryBy("companyName");
                }
            }

            setQuery(e.target.value);
        },
        [queryBy, setQuery, setQueryBy]
    );

    const onSearchClick = useCallback(() => {
        reset();
        setPage(0);
        async(apply);
    }, [reset, apply, setPage]);

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onDownloadCsv = useSwallowEventCallback(async () => {
        setDownloading(true);
        const csv = await loadDnsLog({
            ...initialParams,
            page,
            ...all,
            csv: true,
        });
        downloadCsv(csv, "dns_log.csv");
        setDownloading(false);
    }, [page, all, ...(Object.values(initialParams) || [])]);

    const renderExpanded = useCallback((row) => {
        const items = expandedColumns.map((col) => ({
            key: col.dataIndex,
            label: col.title,
            children: row[col.dataIndex] || "",
        }));
        return <Descriptions bordered items={items} />;
    }, []);

    return (
        <div className="table-dns-log">
            {showToolbar && (
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">
                        {allowSearch && (
                            <>
                                <CountrySelector
                                    placeholder="Country"
                                    size={toolbarSize}
                                    allowClear
                                    showSearch
                                    value={country}
                                    onChange={setCountry}
                                />
                                <Dropdown trigger="click" menu={optionsMenu}>
                                    <Button
                                        size={toolbarSize}
                                        children="Options"
                                        icon={<DownOutlined />}
                                        iconPosition="end"
                                    />
                                </Dropdown>
                                <Input
                                    addonBefore={
                                        <Select
                                            placeholder="Query by"
                                            size={toolbarSize}
                                            value={queryBy}
                                            onChange={setQueryBy}
                                            options={queryOptions}
                                        />
                                    }
                                    allowClear
                                    size={toolbarSize}
                                    onKeyDown={onKeyDown}
                                    prefix={<SearchOutlined />}
                                    onChange={onQueryChange}
                                    value={query}
                                />
                            </>
                        )}
                        {(allowDateRange || allowSearch) && (
                            <Button
                                loading={isLoading}
                                size={toolbarSize}
                                disabled={isLoading}
                                onClick={onSearchClick}
                                children="Search"
                            />
                        )}
                        {allowCsv && (
                            <Tooltip title="Export as CSV">
                                <Button
                                    type="text"
                                    size={toolbarSize}
                                    onClick={onDownloadCsv}
                                    icon={
                                        downloading ? (
                                            <SyncOutlined spin />
                                        ) : (
                                            <DownloadOutlined />
                                        )
                                    }
                                />
                            </Tooltip>
                        )}
                    </div>
                </Portal>
            )}
            <Table
                sticky
                size="small"
                bordered
                loading={data.length === 0 && isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey="id"
                pagination={false}
                locale={data.length === 0 && !isLoading ? locale : undefined}
                expandable={{
                    expandedRowRender: renderExpanded,
                    rowExpandable: () => true,
                }}
            />
            {allowMore && hasMore && (
                <>
                    <br />
                    <Button
                        loading={data.length > 0 && isLoading}
                        onClick={loadNextPage}
                        children="Load more"
                        disabled={isLoading}
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    />
                </>
            )}
        </div>
    );
}

export default DnsLog;
