import supabase from "lib/supabase/main";
import renderGMT from "lib/renderGMT";
import { Link } from "react-router-dom";
import ipaddr from "ipaddr.js";

import IpLink from "components/link/Ip";

import { data2csv } from "lib/csv";

import { loadIpRegistryCidr } from "./ipRegistryCidr";
import { loadIpRegistryLog } from "./ipRegistry";
import CompanyLink from "components/link/Company";
import CountryName from "components/CountryName";
import ThreatName from "components/ThreatName";

export const columns = [
    {
        dataIndex: "time",
        key: "time",
        title: "Time (GMT)",
        render: (time) => renderGMT(time),
    },
    {
        dataIndex: "ip",
        key: "ip",
        title: "IP",
        render: (ip, row) => (
            <IpLink
                ip={ip}
                blacklisted={row.blacklisted}
                blacklistDescription={row.blacklist_description}
            />
        ),
    },
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: (code) => <CountryName country={code} />,
    },
    /*{
        dataIndex: "proxy",
        key: "proxy",
        title: "Proxy"
    },
    {
        dataIndex: "edns",
        key: "edns",
        title: "Edns",
        render: value => value ? "true" : "false"
    },*/
    {
        dataIndex: "question_domain",
        key: "question_domain",
        title: "Question domain",
        render: (qdomain, row) => (
            <Link to={`/reports/domain/${row.domain}`}>{qdomain}</Link>
        ),
    },
    /*{
        dataIndex: "question_class",
        key: "question_class",
        title: "Question class"
    },
    {
        dataIndex: "question_type",
        key: "question_type",
        title: "Question type"
    },
    {
        dataIndex: "answer",
        key: "answer",
        title: "Answer"
    },*/
    {
        dataIndex: "threat",
        key: "threat",
        title: "Threat",
        render: (name, row) => {
            return <ThreatName id={row.threat_id} name={name} />;
        },
    },
    {
        dataIndex: "company_name",
        key: "company_name",
        title: "Company",
        render: (name, row) => <CompanyLink id={row.entity_id} name={name} />,
    },
    {
        dataIndex: "company_domain",
        key: "company_domain",
        title: "Company domain",
    },
];

export const expandedColumns = [
    {
        dataIndex: "proxy",
        key: "proxy",
        title: "Proxy",
    },
    {
        dataIndex: "edns",
        key: "edns",
        title: "Edns",
        render: (value) => (value ? "true" : "false"),
    },
    {
        dataIndex: "question_class",
        key: "question_class",
        title: "Question class",
    },
    {
        dataIndex: "question_type",
        key: "question_type",
        title: "Question type",
    },
    {
        dataIndex: "answer",
        key: "answer",
        title: "Answer",
    },
];

export async function loadTopIps(options = {}) {
    options.report = "topIps";
    const { data, columns, count, error } = await loadDnsLog(options);

    const ips = data.map((row) => row.ip);

    if (ips.length > 0) {
        const { data: cidrs } = await loadIpRegistryCidr({
            pageSize: 100,
            ip: ips,
        });
        const { data: companies } = await loadIpRegistryLog({
            pageSize: cidrs.length,
            cidr: cidrs.map((row) => row.cidr),
        });

        data.forEach((row) => {
            const ip = ipaddr.parse(row.ip);
            const cidr = cidrs.find((c) => ip.match(ipaddr.parseCIDR(c.cidr)));
            if (cidr) {
                row.cidr = cidr.cidr;
                const company = companies.find((c) => c.id === cidr.entity_id);
                row.company_name = company?.name;
                row.entity_id = company?.id;
            }
        });
    }

    return { data, columns, count, error };
}

export async function loadByCountry(options = {}) {
    options.report = "byCountry";
    const { data, columns, count, error } = await loadDnsLog(options);

    return { data, columns, count, error };
}

export async function loadTopDomains(options = {}) {
    options.report = "topDomains";
    const { data, columns, count, error } = await loadDnsLog(options);

    const domains = data.map((row) => row.domain);

    if (domains.length > 0) {
        const { data: threats } = await supabase
            .from("hunt_report_log")
            .select("domain, threat")
            .in("domain", domains);
        //console.log(threats);
        data.forEach((row) => {
            row.threat = threats.find((t) => t.domain === row.domain)?.threat;
        });
    }

    return { data, columns, count, error };
}

export async function loadDnsLog(options = {}) {
    let {
        queryBy = null,
        entityId,
        customerCompanyId,
        domainId,
        threatId,
        ip,
        withCidr = true,
        withCompany = true,
        withThreat = false,
        withGeo = false,
        withBlacklist = true,
        blacklisted = null,
        country = null,
        query,
        dateRange,
        page = 0,
        csv = false,
        single = false,
        report,
        period,
    } = options;

    let pageSize = options.pageSize || 25;

    const hasSlowFilter = !!query || !!entityId;
    pageSize = hasSlowFilter ? (pageSize < 50 ? pageSize : 50) : pageSize;

    if (
        (!dateRange || dateRange.length === 0) &&
        !query &&
        !entityId &&
        !customerCompanyId &&
        !country &&
        !ip &&
        !domainId &&
        !report &&
        !period
    ) {
        period = "lastHour";
    }

    const params = {
        offset: csv ? 0 : page * pageSize,
        limit:
            pageSize !== false
                ? csv
                    ? 1000
                    : (page + 1) * pageSize
                : pageSize,
        queryBy,
        query,
        withCidr,
        withCompany,
        withThreat,
        withGeo,
        withBlacklist,
        blacklisted,
        entityId,
        threatId,
        customerCompanyId,
        country,
        domainId,
        period,
        ip,
        dateRange: dateRange
            ? dateRange.map((d) =>
                  typeof d === "string" ? d : d.toISOString()
              )
            : null,
    };

    const {
        data: { rows, columns, count = 0 },
        error,
    } = await supabase.functions.invoke("query", {
        body: {
            table: "dns_log",
            report,
            options: params,
        },
        method: "POST",
    });

    const hasMore = rows.length >= pageSize && !hasSlowFilter;

    if (csv) {
        const text = data2csv(
            columns.filter((c) => c !== "id" && c !== "raw"),
            rows
        );
        return text;
    }

    if (single) {
        return rows[0];
    }
    return { data: rows, columns, count, error, hasMore };
}
