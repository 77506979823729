import { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import PageCompany from "pages/company/Dashboard";
import PageProfile from "pages/user/Profile";
import PageNotifications from "pages/user/Notifications";

import PageLogsDns from "pages/logs/Dns";
import PageLogsTarpit from "pages/logs/Tarpit";
import PageLogsDnsTarpit from "pages/logs/TarpitPlusDns";

import PageRegistryDomains from "pages/registry/Domains";
import PageRegistryEntities from "pages/registry/Entities";
import PageRegistryThreats from "pages/registry/Threats";
import PageRegistryIps from "pages/registry/Ips";

import PageReportsCountries from "pages/reports/Countries";
import PageReportsThreats from "pages/reports/Malwares";

import PageHuntReports from "pages/hunt/Reports";
import PageHuntOtx from "pages/hunt/Otx";
import PageHuntThreatFoxIoc from "pages/hunt/Ioc";
import PageHuntPreview from "pages/hunt/Preview";
import PageHuntQueue from "pages/hunt/Queue";
import PageHuntRelated from "pages/hunt/Related";
import PageHuntWebCheck from "pages/hunt/WebCheck";
import PageHuntDnsIntel from "pages/hunt/DnsIntel";

import PageIp from "pages/company/Ip";

import PageReportsWebcheck from "pages/reports/Webcheck";

import ScrollToTop from "components/ScrollToTop";
import Header from "components/Header";
import Footer from "components/Footer";

import useAppKey from "hooks/useAppKey";
import useHtmlClass from "hooks/useHtmlClass";

function CompanyView() {
    useHtmlClass("html-company-view");

    const userProfile = useAppKey("userProfile");
    const { company } = userProfile;
    const userAccess = useAppKey("userAccess");
    const huntViewAccess = userAccess?.hunt && userAccess.hunt?.view;
    const registryViewAccess =
        userAccess?.registry && userAccess.registry?.view;
    const reportsViewAccess = userAccess?.report && userAccess.report?.view;
    const logsViewAccess = userAccess?.logs && userAccess.logs?.view;

    return (
        <Fragment>
            <ScrollToTop />
            <Header />
            <Routes>
                {company.map((c) => (
                    <Route
                        key={c.id}
                        path={`/company/${c.id}`}
                        element={<PageCompany />}
                    />
                ))}
                <Route path="/profile" element={<PageProfile />} />
                <Route path="/notifications" element={<PageNotifications />} />
                <Route
                    path="/reports/scan/:domain"
                    element={<PageReportsWebcheck />}
                />
                <Route path="/company/:id/ip/:ip" element={<PageIp />} />

                {huntViewAccess && (
                    <Route path="/hunt/reports" element={<PageHuntReports />} />
                )}
                {huntViewAccess && (
                    <Route path="/hunt/preview" element={<PageHuntPreview />} />
                )}
                {huntViewAccess && (
                    <Route path="/hunt/queue" element={<PageHuntQueue />} />
                )}
                {huntViewAccess && (
                    <Route
                        path="/hunt/intelligence"
                        element={<PageHuntOtx />}
                    />
                )}
                {huntViewAccess && (
                    <Route
                        path="/hunt/ioc"
                        element={<PageHuntThreatFoxIoc />}
                    />
                )}
                {huntViewAccess && (
                    <Route path="/hunt/related" element={<PageHuntRelated />} />
                )}
                {huntViewAccess && (
                    <Route path="/hunt/scan" element={<PageHuntWebCheck />} />
                )}
                {huntViewAccess && (
                    <Route
                        path="/hunt/dnsintel"
                        element={<PageHuntDnsIntel />}
                    />
                )}

                {registryViewAccess && (
                    <Route
                        path="/registry/domains"
                        element={<PageRegistryDomains />}
                    />
                )}
                {registryViewAccess && (
                    <Route
                        path="/registry/entities"
                        element={<PageRegistryEntities />}
                    />
                )}
                {registryViewAccess && (
                    <Route
                        path="/registry/threats"
                        element={<PageRegistryThreats />}
                    />
                )}
                {registryViewAccess && (
                    <Route path="/registry/ips" element={<PageRegistryIps />} />
                )}

                {logsViewAccess && (
                    <Route path="/logs/dns" element={<PageLogsDns />} />
                )}
                {logsViewAccess && (
                    <Route path="/logs/tarpit" element={<PageLogsTarpit />} />
                )}
                {logsViewAccess && (
                    <Route
                        path="/logs/tarpit-dns"
                        element={<PageLogsDnsTarpit />}
                    />
                )}

                {reportsViewAccess && (
                    <Route
                        path="/reports/countries"
                        element={<PageReportsCountries />}
                    />
                )}
                {reportsViewAccess && (
                    <Route
                        path="/reports/threats"
                        element={<PageReportsThreats />}
                    />
                )}

                {company.length > 0 ? (
                    <Route
                        path="*"
                        element={<Navigate to={`/company/${company[0].id}`} />}
                    />
                ) : (
                    <Route path="*" element={<Navigate to="/profile" />} />
                )}
            </Routes>
            <Footer />
        </Fragment>
    );
}

export default CompanyView;
