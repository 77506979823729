import { Button, Popover, Tooltip, Flex } from "antd";
import AppContext from "context/AppContext";
import { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { StopOutlined } from "@ant-design/icons";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

function IpLink({
    ip,
    companyId = null,
    blacklisted = false,
    blacklistDescription = null,
    children,
}) {
    const app = useContext(AppContext);

    const onAddToWatchlist = useCallback(() => {
        app.trigger("add-ips-to-watchlist", [ip]);
    }, [app, ip]);

    const to = useMemo(() => {
        if (companyId) {
            return `/company/${companyId}/ip/${ip}`;
        }
        return `/reports/ip/${ip}`;
    }, [ip, companyId]);

    const link = (
        <Popover
            mouseEnterDelay={0.5}
            content={
                <Button
                    children="Add to watchlist"
                    onClick={onAddToWatchlist}
                />
            }>
            <Link to={to}>{children || ip}</Link>
        </Popover>
    );

    if (blacklisted) {
        return (
            <Flex gap="0.5rem" style={{ display: "inline-flex" }}>
                {link}
                <Tooltip
                    rootClassName="blacklist-tooltip"
                    title={
                        blacklistDescription ? (
                            <Markdown remarkPlugins={[remarkGfm]}>
                                {blacklistDescription}
                            </Markdown>
                        ) : (
                            "Blacklisted"
                        )
                    }>
                    <StopOutlined />
                </Tooltip>
            </Flex>
        );
    } else {
        return link;
    }
}

export default IpLink;
