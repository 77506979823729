import supabase from "lib/supabase/main";

export async function loadIpBlacklist(options = {}) {
    const { ip } = options;
    const { data, error } = await supabase.functions.invoke("query", {
        body: {
            report: "ip_blacklist",
            replica: true,
            options: {
                ip,
            },
        },
        method: "POST",
    });
    return { data, error };
}

export async function getBlacklistedIps(ips = []) {
    if (!ips || !ips.length) {
        return { data: [], error: null };
    }
    const { data, error } = await supabase
        .from("blacklist_ip")
        .select("ip")
        .in("ip", ips);
    const blacklisted = data.map(({ ip }) => ip);
    return { data: blacklisted, error };
}
