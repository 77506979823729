import Page from "components/Page";
import { Portal } from "components/Portal";
import { Spin, Typography, Input, Button, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import NewIpsByDate from "components/report/NewIpsByDate";
import useQuery from "hooks/useQuery";
import supabase from "lib/supabase/main";
import formatNumber from "lib/format/number";
import renderGMT from "lib/renderGMT";
import useDualState from "hooks/useDualState";
import { useCallback, useMemo, useState } from "react";
import ipaddr from "ipaddr.js";
import IpLink from "components/link/Ip";
import CountryName from "components/CountryName";
import { loadIpBlacklist } from "api/ip";

const { Title } = Typography;

const params = [
    {
        name: "query",
        default: "",
        autoApply: true,
        serialize: (v) => (v ? v.trim() : undefined),
    },
];

const columns = [
    {
        dataIndex: "ip",
        key: "ip",
        title: "IP",
        render: (ip, row) => (
            <IpLink
                ip={ip}
                blacklisted={row.blacklisted}
                blacklistDescription={row.blacklist_description}
            />
        ),
    },
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: (code) => <CountryName country={code} />,
    },
    {
        dataIndex: "created_at",
        key: "created_at",
        title: "First seen at",
        render: (d) => renderGMT(d),
    },
    {
        dataIndex: "dns_log_counter",
        key: "dns_log_counter",
        title: "DNS requests",
        render: (v) => formatNumber(parseInt(v)),
    },
    {
        dataIndex: "last_dns_log_match",
        key: "last_dns_log_match",
        title: "Last DNS request",
        render: (d) => renderGMT(d),
    },
    {
        dataIndex: "tarpit_log_counter",
        key: "tarpit_log_counter",
        title: "Data requests",
        render: (v) => formatNumber(parseInt(v)),
    },
    {
        dataIndex: "last_tarpit_log_match",
        key: "last_tarpit_log_match",
        title: "Last data request",
        render: (d) => renderGMT(d),
    },
    {
        dataIndex: "entity_name",
        key: "entity_name",
        title: "Entity",
    },
];

async function loadGeneralStats() {
    return supabase.from("general_stats").select();
}

async function searchIps({ query }) {
    const params = {};
    if (ipaddr.isValid(query)) {
        params.ip = query;
    } else if (
        query.indexOf("/") !== -1 &&
        ipaddr.isValid(query.split("/")[0])
    ) {
        params.cidr = query;
    }

    const { data, error } = await supabase.functions.invoke("query", {
        body: {
            report: "ip_search",
            options: params,
        },
        method: "POST",
    });
    const ips = data?.map((row) => row.ip) || [];

    if (ips.length > 0) {
        const { data: blacklisted } = await loadIpBlacklist({ ip: ips });
        data.forEach((row) => {
            const bl = blacklisted?.find((bl) => bl.ip === row.ip);
            row.blacklisted = !!bl;
            row.blacklist_description = bl?.description || null;
        });
    }

    return { data, error };
}

function PageIps() {
    const { query, setQuery } = useDualState({
        params,
        mode: "both",
    });
    const [appliedQuery, setAppliedQuery] = useState(null);

    const { data: generalStats, isLoading: isStatsLoading } = useQuery(
        loadGeneralStats,
        [],
        {
            initialData: [],
        }
    );

    const validQuery = useMemo(() => {
        if (!query) {
            return false;
        } else if (ipaddr.isValid(query)) {
            return true;
        } else if (
            query.indexOf("/") !== -1 &&
            ipaddr.isValid(query.split("/")[0])
        ) {
            return true;
        }
        return false;
    }, [query]);

    const { data: ips, isLoading } = useQuery(
        searchIps,
        [appliedQuery, validQuery],
        {
            initialData: [],
            enabled: validQuery && appliedQuery,
            params: {
                query: appliedQuery,
            },
        }
    );

    const stats = generalStats?.[0];

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                setAppliedQuery(query);
            }
        },
        [setAppliedQuery, query]
    );

    const onQueryChange = useCallback(
        (e) => {
            setQuery(e.target.value);
        },
        [setQuery]
    );

    const onSearchClick = useCallback(() => {
        setAppliedQuery(query);
    }, [setAppliedQuery, query]);

    return (
        <Page className="page-ips">
            <Portal host="header">
                <Title level={1}>IP search</Title>
                <div className="page-general-stats dashboard-general-stats">
                    <Spin spinning={isStatsLoading}>
                        <div className="dashboard-general-stats-item">
                            <div className="stats-card-front">
                                <h4>
                                    {stats ? (
                                        formatNumber(stats.ip_count)
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </h4>
                                <p>Victim IPs registered</p>
                            </div>
                        </div>
                    </Spin>
                    <Spin spinning={isStatsLoading}>
                        <div className="dashboard-general-stats-item">
                            <div className="stats-card-front">
                                <h4>
                                    {stats ? (
                                        formatNumber(stats.new_ip_count)
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </h4>
                                <p>New IPs per day</p>
                            </div>
                        </div>
                    </Spin>
                </div>
                <NewIpsByDate showToolbar={false} chartType="bar" />
            </Portal>
            <div className="toolbar">
                <Input
                    allowClear
                    size="large"
                    disabled={isLoading}
                    onKeyDown={onKeyDown}
                    prefix={<SearchOutlined />}
                    onChange={onQueryChange}
                    value={query}
                />
                <Button
                    size="large"
                    loading={isLoading}
                    disabled={isLoading || !validQuery}
                    onClick={onSearchClick}
                    children="Search"
                />
            </div>
            <Spin spinning={isLoading}>
                <Table
                    sticky
                    size="small"
                    bordered
                    loading={isLoading}
                    dataSource={ips}
                    columns={columns}
                    rowKey="ip"
                    expandable={false}
                    pagination={false}
                />
            </Spin>
        </Page>
    );
}

export default PageIps;
