import { useState } from "react";
import { Segmented, Spin, Typography } from "antd";
import moment from "moment";
import Chart from "react-apexcharts";

import useQuery from "hooks/useQuery";
import fillDates from "lib/fillDates";
import periodOptions from "lib/periodOptions";
import supabase from "lib/supabase/main";
import useApexChart from "hooks/useApexChart";

const { Title } = Typography;

async function loadNewIpsByDate(options = {}) {
    const {
        data: { rows, columns },
        error,
    } = await supabase.functions.invoke("query", {
        body: {
            report: "new_ips_by_date",
            options,
            replica: true,
        },
        method: "POST",
    });

    return { data: rows, columns, error };
}

function NewIpsByDate({
    title = "New IPs by date",
    showToolbar = true,
    chartType = "area",
}) {
    const [period, setPeriod] = useState("last30");

    const { data, isLoading } = useQuery(loadNewIpsByDate, [period], {
        prepare: (data) => {
            return fillDates(
                period,
                data,
                "date",
                (d) => d.format("YYYY-MM-DD"),
                { cnt: 0 }
            );
        },
        map: (row) => ({
            date: moment(row.date).utc().toDate().getTime(),
            cnt: parseInt(row.cnt),
        }),
        params: { period },
    });

    const chart = useApexChart({
        data,
        type: chartType,
        axes: {
            y: {
                name: "New IPs",
            },
        },
    });

    return (
        <div className="small-report report-new-ips-by-date">
            {showToolbar && (
                <div className="toolbar">
                    {title && <Title level={4}>{title}</Title>}
                    <Segmented
                        className="toolbar-right"
                        value={period}
                        onChange={setPeriod}
                        options={periodOptions}
                    />
                </div>
            )}
            <Spin spinning={isLoading}>
                <div className="chart-wrapper">
                    {data.length > 0 && <Chart {...chart} />}
                </div>
            </Spin>
        </div>
    );
}

export default NewIpsByDate;
