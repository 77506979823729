import supabase from "lib/supabase/main";
import supabasePayloads from "lib/supabase/payloads";
import renderGMT from "lib/renderGMT";
import { Link } from "react-router-dom";
import ipaddr from "ipaddr.js";

import IpLink from "components/link/Ip";
import CompanyLink from "components/link/Company";

import { data2csv } from "lib/csv";
import { loadIpRegistryCidr } from "./ipRegistryCidr";
import { loadIpRegistryLog } from "./ipRegistry";
import CountryName from "components/CountryName";
import ThreatName from "components/ThreatName";

export const columns = [
    {
        dataIndex: "time",
        key: "time",
        title: "Time (GMT)",
        render: (time) => renderGMT(time),
    },
    {
        dataIndex: "source_ip",
        key: "source_ip",
        title: "Source IP",
        render: (ip, row) => {
            if (row.forwarded_for && row.forwarded_for !== ip) {
                return (
                    <>
                        <IpLink
                            ip={ip}
                            blacklisted={row.blacklisted}
                            blacklistDescription={row.blacklist_description}
                        />
                        (<IpLink ip={row.forwarded_for} />)
                    </>
                );
            }
            return (
                <IpLink
                    ip={ip}
                    blacklisted={row.blacklisted}
                    blacklistDescription={row.blacklist_description}
                />
            );
        },
    },
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: (code) => <CountryName country={code} />,
    },

    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: (domain, row) => {
            if (domain) {
                return <Link to={`/reports/domain/${domain}`}>{domain}</Link>;
            }
            return "";
        },
    },
    {
        dataIndex: "threat",
        key: "threat",
        title: "Threat",
        render: (threat, row) => {
            return <ThreatName id={row.threat_id} name={threat} />;
        },
    },
    {
        dataIndex: "headers",
        key: "headers",
        title: "Headers",
        render: (content) => {
            if (content) {
                if (content.length < 20) {
                    return content;
                }
                return content.substring(0, 20) + "...";
            }
            return "";
        },
    },
    {
        dataIndex: "content",
        key: "content",
        title: "Payload",
        render: (content) => {
            if (content) {
                if (content.length < 20) {
                    return content;
                }
                return content.substring(0, 20) + "...";
            }
            return "";
        },
    },
    {
        dataIndex: "company_name",
        key: "company_name",
        title: "Company",
        render: (name, row) => <CompanyLink id={row.entity_id} name={name} />,
    },
    /*{
        dataIndex: "company_domain",
        key: "company_domain",
        title: "Domain"
    },*/

    {
        dataIndex: "dns_log_exists",
        key: "dns_log_exists",
        title: "Exists in Dns Log",
        render: (b) => (b ? "Yes" : "No"),
    },
];

export async function loadTopIps(options = {}) {
    options.report = "topIps";
    const { data, columns, count, error } = await loadTarpitLog(options);

    const ips = data.map((row) => row.source_ip);

    if (ips.length > 0) {
        const { data: cidrs } = await loadIpRegistryCidr({
            pageSize: 100,
            ip: ips,
        });
        const { data: companies } = await loadIpRegistryLog({
            pageSize: cidrs.length,
            cidr: cidrs.map((row) => row.cidr),
        });

        data.forEach((row) => {
            const ip = ipaddr.parse(row.source_ip);
            const cidr = cidrs.find((c) => ip.match(ipaddr.parseCIDR(c.cidr)));
            if (cidr) {
                row.cidr = cidr.cidr;
                const company = companies.find((c) => c.id === cidr.entity_id);
                row.company = company?.name;
                row.entity_id = company?.id;
            }
        });
    }

    return { data, columns, count, error };
}

export async function loadByCountry(options = {}) {
    options.report = "byCountry";
    const { data, columns, count, error } = await loadTarpitLog(options);

    return { data, columns, count, error };
}

export async function loadTarpitLog(options = {}) {
    let {
        queryBy = null,
        entityId,
        customerCompanyId,
        domainId,
        payloadId,
        headersId,
        threatId,
        ip,
        forwardedFor,
        withCompany = true,
        withPayload = false,
        hasPayload = null,
        withHeaders = false,
        withDnsLogFlag = false,
        withThreat = false,
        withBlacklist = true,
        blacklisted = null,
        matchesDns = null,
        country = null,
        gzipped = null,
        query,
        dateRange,
        page = 0,
        pageSize = 20,
        csv = false,
        single = false,
        report,
        period,
    } = options;

    if (
        (!dateRange || dateRange.length === 0) &&
        (!query || !queryBy) &&
        !entityId &&
        !customerCompanyId &&
        !country &&
        !ip &&
        !domainId &&
        !report &&
        !period &&
        !payloadId &&
        !headersId
    ) {
        period = "lastHour";
    }

    if (payloadId && Array.isArray(payloadId) && payloadId.length > 100) {
        payloadId = payloadId.slice(0, 100);
    }

    const params = {
        offset: csv ? 0 : page * pageSize,
        limit: pageSize !== false ? (csv ? 1000 : pageSize) : 100, //((page + 1) * pageSize) : pageSize,
        queryBy,
        query,
        withPayload: false,
        hasPayload,
        withHeaders: false,
        withCompany,
        withThreat,
        withBlacklist,
        blacklisted,
        withDnsLogFlag: matchesDns === true ? false : withDnsLogFlag,
        matchesDnsLog: matchesDns,
        entityId,
        customerCompanyId,
        domain: domainId,
        payloadId,
        headersId,
        threatId,
        period,
        country,
        gzipped,
        ip,
        forwardedFor,
        dateRange: dateRange
            ? dateRange.map((d) =>
                  typeof d === "string" ? d : d.toISOString()
              )
            : null,
    };

    const {
        data: { rows, columns = [], count = 0 },
        error,
    } = await supabase.functions.invoke("query", {
        body: {
            table: "tarpit_log",
            report,
            options: params,
        },
        method: "POST",
    });

    const promises = [];

    if (withHeaders) {
        const headerIds = rows.map((r) => r.headers_id).filter((h) => !!h);
        columns.push("headers");
        if (headerIds.length > 0) {
            promises.push(
                new Promise(async (resolve) => {
                    const { data } = await supabasePayloads
                        .from("tarpit_header")
                        .select()
                        .in("id", headerIds);
                    data.forEach((header) => {
                        rows.forEach((row) => {
                            if (row.headers_id === header.id) {
                                row.headers = header.content;
                            }
                        });
                    });
                    resolve();
                })
            );
        }
    }

    if (withPayload) {
        const payloadIds = rows.map((r) => r.payload_id).filter((p) => !!p);
        columns.push("content");
        if (payloadIds.length > 0) {
            promises.push(
                new Promise(async (resolve) => {
                    const { data } = await supabasePayloads
                        .from("tarpit_payload")
                        .select()
                        .in("id", payloadIds);
                    data.forEach((payload) => {
                        rows.forEach((row) => {
                            if (row.payload_id === payload.id) {
                                row.content = payload.content;
                                row.gzipped = payload.gzipped;
                                row.failed_decoding = payload.failed_decoding;
                            }
                        });
                    });
                    resolve();
                })
            );
        }
    }

    if (promises.length > 0) {
        await Promise.allSettled(promises);
    }

    const hasMore = rows.length >= pageSize;

    if (csv) {
        const text = data2csv(
            columns.filter((c) => c !== "id" && c !== "raw"),
            rows
        );
        return text;
    }

    if (single) {
        return rows[0];
    }

    let filteredColumns = [];
    if (!rows.find((r) => !!r.domain)) {
        filteredColumns = columns.filter((c) => {
            return ["headers", "domain", "protocol"].indexOf(c) === -1;
        });
    } else {
        filteredColumns = columns;
    }

    return { data: rows, columns: filteredColumns, count, error, hasMore };
}
